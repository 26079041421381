<template>
  <div class="bodyBodi bodiColor">
    <div id="logo_bodi" class="panelImg">
      <img
        alt="logo bodi"
        src="@/assets/images/logo_bodi.png"
        class="logo mt5"
      />
    </div>
    <div style="margin-top: 60px; margin-bottom: 60px; text-align: left;">
      <h3 style="margin: 0 10px;">
        La procedura di installazione prevede i seguenti passi:
      </h3>
      <ol style="padding: 10px;">
        <li>
          Clicca il tasto "condividi"
          <img
            alt="tasto condividi"
            src="@/assets/images/share.png"
            style="width: 25px;"
          />
          che trovi sulla destra della barra degli indirizzi o in fondo allo
          schermo;
        </li>
        <li>
          Si aprirà un menu dal quale scegliere l'opzione "aggiungi a schermata
          home";
        </li>
        <li>
          Il dispositivo chiederà nuovamente conferma alla opzione "Aggiungi a
          Home" cliccando la voce "Aggiungi";
        </li>
        <li>
          Al termine dell'operazione il programma di navigazione si chiuderà
          automaticamente e l'icona di accesso al servizio BODI sarà disponibile
          sullo schermo del dispositivo come una qualsiasi APP.
        </li>
      </ol>
    </div>
    <div id="logo_horta" class="panelImg">
      <img
        alt="logo horta"
        src="@/assets/images/logo_horta_bianco.png"
        class="mt5 wp45"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  created() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      window.location.href = 'http://bo-di.it/'
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  padding: 0;
}

li {
  margin: 0 10px;
}

li {
  list-style: decimal;
}

a {
  color: #42b983;
}

.iframe {
  height: 97vh;
}

.logo {
  width: 60%;
  margin-top: 50px;
}

.wp45 {
  width: 45%;
}

.bodiColor {
  background-color: #5b4b3e;
}

.bodyBodi {
  margin: auto;
  max-width: 500px;
  color: #fff;
  font: 13px/1.5 Arial, Helvetica, sans-serif;
  display: table;
  height: 95vh;
}
</style>
